import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Seo from './../components/Seo'
import SectionProvider from '../components/Layout/SectionProvider'

const Service = ({ 
    data: {
        contentfulItemService: {
            name,
            sections,
            seo
        }
    }
}) => {
    return (
        <>
            <Seo 
                title={name}
            />
            {sections && <SectionProvider sections={sections} />}
        </>
    )
}

export default Service

export const query = graphql`
	query($id: String!) {
		contentfulItemService(id: {eq: $id}) {
            sections {
                __typename ... on Node {
                    ... on ContentfulSectionBanner {
                        ...sectionBanner
                    }
                    ... on ContentfulSectionHeroBanner {
                        ...sectionHeroBanner
                    }
                    ... on ContentfulSectionRow {
                        ...sectionRow
                    }
                }
            }
        }
	}
`